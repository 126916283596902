import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Framework from '../components/framework';
import Product from '../components/shop/product';
import SEO from '../components/framework/seo';

export const query = graphql`
	query Products {
		allProductsCsv {
			edges {
				node {
					...ProductFragment
				}
			}
		}

		background: file(relativePath: { eq: "shop/background.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1140) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;

const ShopPage = ({ data, location }) => {
	const header = {
		image: data.background.childImageSharp.fluid.src,
		subTitle: 'Denizko Art',
		title: 'Shop',
	};
	const products = data.allProductsCsv.edges;

	return (
		<Framework header={header} location={location}>
			<SEO
				description="Clothes designed by Deniz"
				image={data.background.childImageSharp.fluid.src}
				location={location}
				tags="denizko, denizko art, clothes, t-shirt"
				title="Denizko art shop"
			/>
			<div className="container">
				<div className="row row-cols-1 row-cols-md-3">
					{products.map((product, index) => {
						return <Product data={product.node} key={index} />;
					})}
				</div>

				<div className="text-center">
					<a
						className="btn btn-outline-secondary"
						href="https://shop.denizko.me"
						rel="noopener noreferrer"
						target="_blank"
						title="Denizko shop"
					>
						More products
					</a>
				</div>
			</div>
		</Framework>
	);
};

ShopPage.propTypes = {
	data: PropTypes.shape({
		allProductsCsv: PropTypes.object.isRequired,
		background: PropTypes.object,
	}),
	location: PropTypes.object.isRequired,
};

export default ShopPage;
